function cookiesEnabled() {
  // Quick test if browser has cookieEnabled host property
  if (navigator.cookieEnabled) return true;
  // Create cookie
  document.cookie = "cookietest=1";
  var ret = document.cookie.indexOf("cookietest=") != -1;
  // Delete cookie
  document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
  return ret;
}

function getSqzlCookie() {
  var i,
    x,
    y,
    ARRcookies = document.cookie.split(";");
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
    x = x.replace(/^\s+|\s+$/g, "");
    if (x === 'sqzllocal') {
      return unescape(y);
    }
  }
}

(function () {
  var el = document.getElementById('tcjs');
  var domain_default = el.getAttribute('data-domain-default');
  var environment = '';
  // Create IE + others compatible event handler
  var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  var eventer = window[eventMethod];
  var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
  // Listen to message from child window
  eventer(messageEvent, function (e) {
    if (e.origin === 'https://' + domain_default || e.origin === 'http://' + domain_default) {
      var window_location_origin = window.location.protocol + '//' + window.location.host + ':' + window.location.port;
      if (typeof (e.data) !== 'object') {
        // don't send tcID to session if it's a sqzl message
        if (e.data.match(/^sqzl/)) {
          return;
        }
        if (e.data.match(/functionName/)) {
          return;
        }
        var url = window_location_origin + environment + '/_tcs?tcIDtoSession=' + e.data;
        url += '&cookies_enabled=' + cookiesEnabled();
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", url, true);
        xmlHttp.send();
      }
    }
  }, false, true);
})();

var el = document.getElementById('tcjs');
var domain_default = el.getAttribute('data-domain-default');

var iframe = document.createElement('iframe');
iframe.height = '1px';
iframe.width = '1px';
iframe.src = "//" + domain_default + "/_tc?action=setIfNoExists&name=tcID";
document.body.appendChild(iframe);

if (getSqzlCookie() !== undefined && getSqzlCookie() !== 'undefined' && getSqzlCookie() !== null && getSqzlCookie() !== '' ) {
  if ( window.location.host !== domain_default ) {
    var iframe = document.createElement('iframe');
    iframe.height = '1px';
    iframe.width = '1px';
    iframe.src = "//" + domain_default + "/_sqzl" + "?sqzllocal=" + getSqzlCookie();
    document.body.appendChild(iframe);
  }
}